var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name === 'Product')?_c('div',{attrs:{"id":"Product"}},[_c('div',{staticClass:"product-header"},[_c('Activity')],1),_c('div',{attrs:{"id":"product-my"}},[_c('a-button',[_c('router-link',{attrs:{"to":{ name: 'Agreement' }}},[_vm._v(" 《购买协议》 ")])],1)],1),_c('div',{attrs:{"id":"product-list"}},[_vm._l((_vm.list),function(i,k){return _c('a-row',{key:k},[(k === 'clustersList')?_c('a-col',{attrs:{"span":24}},[(i.source)?_c('div',{staticClass:"top"},[_c('div',{staticClass:"title components"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(i.source)+" ")]),_c('div',{staticClass:"right"},[_vm._v("(点击产品即可查看详情)")])])]):_vm._e(),_c('div',{staticClass:"bottom"},[_c('a-list',{attrs:{"item-layout":"horizontal","data-source":i.clusters,"loading":i.loading},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{directives:[{name:"show",rawName:"v-show",value:(index < 3 ? true : i.unfoldShow),expression:"index < 3 ? true : i.unfoldShow"}],class:`clusters-item clusters-item-` + index},[_c('a-button',[_c('router-link',{attrs:{"to":i.source.substring(0, 1) !== 'B' &&
                    i.source.substring(0, 1) !== 'C'
                      ? {
                          name: 'Purchase-Details',
                          params: {
                            source: i.source.substring(0, 1),
                            sort: 'clusters',
                            id: item.cluster_id,
                          },
                        }
                      : {}}},[_c('div',{staticClass:"top"},[_c('p',[(item.node_name)?[_vm._v(" "+_vm._s(item.node_name)+" - ")]:_vm._e(),[_vm._v(_vm._s(item.title))]],2)]),_c('div',{staticClass:"bottom"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("挖矿币种: ")]),_c('span',[_vm._v("Filecoin")])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("结算周期: ")]),_c('span',[_vm._v(_vm._s(item.settlement_cycle))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("产币期限: ")]),_c('span',[_vm._v(_vm._s(item.contract_period))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("托管服务费: ")]),_c('span',[_vm._v(_vm._s(item.service_rate))])])],1)],1),(
                      i.source.substring(0, 1) !== 'B' &&
                      i.source.substring(0, 1) !== 'C'
                    )?_c('div',{staticClass:"button"},[_c('a-button',[_vm._v("点击立即购买")])],1):_vm._e()])],1)],1)}}],null,true)})],1)]):_vm._e()],1)}),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title components"},[_c('div',{staticClass:"left"},[_vm._v(" 基金 ")]),_c('div',{staticClass:"right"},[_vm._v("(点击产品即可查看详情)")])])]),_c('div',{staticClass:"bottom"},[_c('a-list',{attrs:{"item-layout":"horizontal","data-source":_vm.moneyManage.items,"loading":_vm.moneyManage.loading},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{class:`moneyManage-item moneyManage-item-` + index},[_c('a-button',[_c('router-link',{attrs:{"to":{
                    name: 'Money-Manage-Details',
                    params: {
                      id: item.financial_id,
                    }
                  }}},[_c('div',{staticClass:"top"},[_c('p',[_vm._v(" "+_vm._s(item.financial_name)+" ")])]),_c('div',{staticClass:"bottom"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("最小购买: ")]),_c('span',[_vm._v(_vm._s(_vm._f("filterDecimalPoint")(item.min_purchase)))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("递增额度: ")]),_c('span',[_vm._v(_vm._s(_vm._f("filterDecimalPoint")(item.incremental_amount)))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("收益利率: ")]),_c('span',[_vm._v(_vm._s(_vm._f("filterDecimalPoint")(item.monthly_interest_rate)))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("生效天数: ")]),_c('span',[_vm._v(_vm._s(item.delay))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("基金周期: ")]),_c('span',[_vm._v(_vm._s(item.cycle))])]),_c('a-col',{attrs:{"span":12}},[_c('span',[_vm._v("收益模式: ")]),_c('span',[_vm._v(_vm._s(item.mode))])])],1)],1),_c('div',{staticClass:"button"},[_c('a-button',[_vm._v("点击立即购买")])],1)])],1)],1)}}],null,false,3757579651)})],1)])],1)],2)]):_c('router-view',{key:_vm.$route.path + Math.random()})
}
var staticRenderFns = []

export { render, staticRenderFns }