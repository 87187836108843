
<template>
  <div id="Product" v-if="$route.name === 'Product'">
    <div class="product-header">
      <Activity />
    </div>
    <div id="product-my">
      <a-button>
        <router-link :to="{ name: 'Agreement' }"> 《购买协议》 </router-link>
      </a-button>
    </div>
    <div id="product-list">
      <a-row v-for="(i, k) in list" :key="k">
        <a-col :span="24" v-if="k === 'clustersList'">
          <div class="top" v-if="i.source">
            <div class="title components">
              <div class="left">
                {{ i.source }}
              </div>
              <div class="right">(点击产品即可查看详情)</div>
            </div>
          </div>
          <div class="bottom">
            <a-list
              item-layout="horizontal"
              :data-source="i.clusters"
              :loading="i.loading"
            >
              <a-list-item
                slot="renderItem"
                slot-scope="item, index"
                v-show="index < 3 ? true : i.unfoldShow"
                :class="`clusters-item clusters-item-` + index"
              >
                <a-button>
                  <router-link
                    :to="
                      i.source.substring(0, 1) !== 'B' &&
                      i.source.substring(0, 1) !== 'C'
                        ? {
                            name: 'Purchase-Details',
                            params: {
                              source: i.source.substring(0, 1),
                              sort: 'clusters',
                              id: item.cluster_id,
                            },
                          }
                        : {}
                    "
                  >
                    <div class="top">
                      <p>
                        <template v-if="item.node_name">
                          {{ item.node_name }} -
                        </template>
                        <template>{{ item.title }}</template>
                      </p>
                    </div>
                    <div class="bottom">
                      <a-row>
                        <a-col :span="12">
                          <span>挖矿币种: </span>
                          <span>Filecoin</span>
                        </a-col>
                        <a-col :span="12">
                          <span>结算周期: </span>
                          <span>{{ item.settlement_cycle }}</span>
                        </a-col>
                        <a-col :span="12">
                          <span>产币期限: </span>
                          <span>{{ item.contract_period }}</span>
                        </a-col>
                        <a-col :span="12">
                          <span>托管服务费: </span>
                          <span>{{ item.service_rate }}</span>
                        </a-col>
                      </a-row>
                    </div>
                    <div
                      class="button"
                      v-if="
                        i.source.substring(0, 1) !== 'B' &&
                        i.source.substring(0, 1) !== 'C'
                      "
                    >
                      <a-button>点击立即购买</a-button>
                    </div>
                  </router-link>
                </a-button>
              </a-list-item>
            </a-list>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <div class="top">
            <div class="title components">
              <div class="left">
                基金
              </div>
              <div class="right">(点击产品即可查看详情)</div>
            </div>
          </div>
          <div class="bottom">
            <a-list
              item-layout="horizontal"
              :data-source="moneyManage.items"
              :loading="moneyManage.loading"
            >
              <a-list-item
                slot="renderItem"
                slot-scope="item, index"
                :class="`moneyManage-item moneyManage-item-` + index"
              >
                <a-button>
                  <router-link
                    :to="{
                      name: 'Money-Manage-Details',
                      params: {
                        id: item.financial_id,
                      }
                    }"
                  >
                    <div class="top">
                      <p>
                        {{ item.financial_name }}
                      </p>
                    </div>
                    <div class="bottom">
                      <a-row>
                        <!--<a-col :span="12">
                          <span>总量额度: </span>
                          <span>{{ item.total_quota | filterDecimalPoint }}</span>
                        </a-col> -->
                        <a-col :span="12">
                          <span>最小购买: </span>
                          <span>{{ item.min_purchase | filterDecimalPoint }}</span>
                        </a-col>
                        <a-col :span="12">
                          <span>递增额度: </span>
                          <span>{{ item.incremental_amount | filterDecimalPoint }}</span>
                        </a-col>
                        <!--<a-col :span="12">
                          <span>最大购买: </span>
                          <span>{{ item.max_purchase | filterDecimalPoint }}</span>
                        </a-col> -->
                        <a-col :span="12">
                          <span>收益利率: </span>
                          <span>{{ item.monthly_interest_rate | filterDecimalPoint }}</span>
                        </a-col>
                        <a-col :span="12">
                          <span>生效天数: </span>
                          <span>{{ item.delay }}</span>
                        </a-col>
                        <a-col :span="12">
                          <span>基金周期: </span>
                          <span>{{ item.cycle }}</span>
                        </a-col>
                        <a-col :span="12">
                          <span>收益模式: </span>
                          <span>{{ item.mode }}</span>
                        </a-col>
                        
                        <!--<a-col :span="24">
                          <span>开始日期: </span>
                          <span>{{ item.start_date  }}</span>
                        </a-col> -->
                      </a-row>
                    </div>
                    <div
                      class="button"
                    >
                      <a-button>点击立即购买</a-button>
                    </div>
                  </router-link>
                </a-button>
              </a-list-item>
            </a-list>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <router-view v-else :key="$route.path + Math.random()" />
</template>

<script >
import Vue from "vue";
import { List } from "ant-design-vue";
Vue.use(List);

import Activity from "@/components/Activity";

export default {
  // 引入的组件
  components: { Activity },
  data() {
    return {
      list: {
        clustersList: {
          clusters: [],
          source: "",
          unfoldShow: false,
          loading: false,
        },
      },
      moneyManage: {
        items: [],
        loading: false,
      }
    };
  },
  filters: {
    filterDecimalPoint(v) {
      return (v + '').replace(/(\.\d{0,2})\d+/, '')
    }
  },
  methods: {
    async getClustersList() {
      this.list.clustersList.loading = true;
      await this.$axios.get("power/clusters").then((res) => {
           this.list.clustersList.loading = false;
        // this.list.clustersList.loading = true;
        if (res.code === 0) {
          if (!res.data.clusters) res.data.clusters = [];
          Object.assign(this.list.clustersList, res.data);
        }
      });
    },
    // async getFinancialsList() {
    //   this.list.financialsLoading = true;
    //   await this.$axios.get("financials").then((res) => {
    //     this.list.financialsLoading = false;
    //     if (res.code === 0) this.list.financialsList = res.data;
    //   });
    // },

    /* 获取基金列表 */
    async getMoneyManageList() {
      this.moneyManage.loading = true;
      await this.$axios
        // 基金产品请求地址
        .get("financials")
        .then((res) => {
          if (res.code === 0) {
            this.moneyManage.items = res.data ?? [];
          }
        })
        .finally(() => {
          this.moneyManage.loading = false;
        });
    },
  },
  created() {
    this.getMoneyManageList();
    this.getClustersList()
  }
};
</script>

<style  lang="less">
#Product {
  .product-header {
    margin: -1em -1em -0em -1em;
  }

  #product-my {
    margin-top: 0.3rem;
    display: flex;
    justify-content: center;

    > .ant-btn:first-child {
      border-color: rgba(221, 63, 88, 1) !important;
      background-color: rgba(221, 63, 88, 1) !important;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .ant-btn:last-child {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  #product-list {
    .ant-col {
      > .top {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;

        > .left {
          > span {
            //: 0.6em;
            color: #dcdcdc;
          }
        }

        > .right {
          //: 0.25rem;
          color: #565e9a;
        }
      }

      > .bottom {
        > .ant-list {
          .ant-list-item {
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
            // padding: 0.2rem;
            margin-bottom: 0.2rem;
            padding: 0;
            border-bottom: 0;

            > .ant-btn {
              border-radius: 0.2rem;
              background-color: #3d447b;
              padding: 0;

              > a {
                max-width: 100%;
                padding: 0.5em 1em;
                position: relative;

                * {
                  text-align: left;
                }

                > .status {
                  position: absolute;
                  z-index: 1;
                  @TR: 0.2em;
                  top: @TR;
                  right: @TR;
                  width: 5em;
                  height: 5em;
                  // padding: 0.1em 0.5em;
                  // background-color: khaki;
                  // background-image: url("/images/product/1.png");
                  background-size: 100%;
                  background-repeat: no-repeat;
                  background-position: center;
                  // transform: rotate(7deg);
                  // -ms-transform: rotate(7deg); /* IE 9 */
                  // -moz-transform: rotate(7deg); /* Firefox */
                  // -webkit-transform: rotate(7deg); /* Safari 和 Chrome */
                  // -o-transform: rotate(7deg); /* Opera */
                }

                > .top {
                  font-size: 1.2em;
                  font-weight: bold;

                  > p:first-child {
                    color: rgba(221, 63, 88, 1);
                  }
                }

                > .bottom {
                  > .ant-row > .ant-col {
                    line-height: 2em;

                    > span:first-child {
                      color: #ccc;
                    }
                  }
                }

                > .button {
                  margin-top: 1em;
                }
              }
            }
          }
        }

        > .ant-btn {
          height: 2em;
          line-height: 2em;
          background-color: #3d447b;

          &:focus {
            background-color: #3d447b;
          }
        }
      }
    }

    > .ant-row:not(:first-child)
      > .ant-col
      > .bottom
      > .ant-row
      > .ant-col
      > .ant-btn
      > a
      > .bottom
      > .ant-row
      > .ant-col {
      display: flex;
      justify-content: space-between;

      > span {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
</style>
